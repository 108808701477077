<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" class="ele-form-search d-flex"
               @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>

        <el-form-item label="姓名:" label-width="50px" class="w-200">
          <el-input v-model="table.where.name" placeholder="" clearable/>
        </el-form-item>
        <el-form-item label="手机号:" label-width="62px" class="w-200">
          <el-input v-model="table.where.phone" placeholder="" clearable/>
        </el-form-item>
        <el-form-item label="省:" label-width="35px" class="w-170">
          <el-select v-model="table.where.pid"  @change="handleChangeProv(table.where.pid)" placeholder='请选择省'>
            <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市:" label-width="35px" class="w-150">
          <el-select v-model="table.where.cid"  @change="handleChangeCity(table.where.cid),$forceUpdate()" placeholder='请选择市'>
            <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="县/区:" label-width="62px" class="w-200">
          <el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()">
            <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon ml-20">搜索</el-button>
          <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
          <el-button icon="el-icon-download" type="primary" @click="exportExcel" v-if="permission.includes('sys:order:export')">导出</el-button>
        </el-form-item>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left"/>
          <el-table-column type="index" :index="index" label="编号" width="60" align="center"  fixed="left" show-overflow-tooltip/>
          <el-table-column prop="true_name" label="跑腿姓名"  show-overflow-tooltip/>
          <el-table-column prop="sex" label="性别"  show-overflow-tooltip/>
          <el-table-column prop="phone" label="手机号"  show-overflow-tooltip min-width="120"/>
          <el-table-column prop="driver_area" label="所属地区"  show-overflow-tooltip min-width="220"/>
          <el-table-column prop="order_rule" label="服务费收取比例（%）"  show-overflow-tooltip min-width="220"/>
          <el-table-column prop="money" label="累计接单收益(元)"  show-overflow-tooltip/>
          <el-table-column prop="order_total" label="累计接单量"  show-overflow-tooltip/>
          <el-table-column prop="invite_money" label="累计推广收益(元)"  show-overflow-tooltip/>
          <el-table-column prop="invite_money" label="累计推广量"  show-overflow-tooltip/>
          <el-table-column prop="credit" label="评分"  show-overflow-tooltip/>
          <el-table-column prop="legwork_device" label="设备"  show-overflow-tooltip/>
          <el-table-column prop="total_time" label="累计在线时长(分钟)"  show-overflow-tooltip min-width="145"/>
          <el-table-column label="最近登录时间"  show-overflow-tooltip min-width="120">
            <template slot-scope="{row}">{{ row.active_time*1000 | toDateString }}</template>
          </el-table-column>

          <el-table-column prop="online_order_nums" label="正在进行中的订单个数"  show-overflow-tooltip min-width="180"/>
          <el-table-column label="注册时间"  show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
          </el-table-column>

          <el-table-column label="操作" width="180px" align="center" :resizable="false"  fixed="right">
            <template slot-scope="{row}">


              <el-link @click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false" v-if="permission.includes('sys:legwork:view')">查看</el-link>
              <el-link @click="handleEdit(row)" icon="el-icon-edit" type="primary" :underline="false" v-if="permission.includes('sys:legwork:edit')">编辑</el-link>

              <el-dropdown @command="command => dropClick(command,row)" >
                <span class="el-dropdown-link" style="margin-left: 10px;">
                  <el-link type="primary" icon="el-icon-more" :underline="false"></el-link>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-s-order" v-if="permission.includes('sys:driver:out_list')" command="out_list">开工记录</el-dropdown-item>
<!--                  <el-dropdown-item icon="el-icon-user-solid" command="check_list" v-if="permission.includes('sys:driver:check_list')">人脸检测</el-dropdown-item>-->
                  <el-dropdown-item icon="el-icon-delete" v-if="permission.includes('sys:legwork:delete')" command="remove">删除</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-s-release" v-if="permission.includes('sys:driver:blackData')" command="blackData">加入黑名单</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-error" v-if="permission.includes('sys:driver:forlimit')" command="forlimit">限号</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>



            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 查看司机详情 -->
    <el-dialog v-dialogDrag title="查看司机详情" :visible.sync="dialogTableVisibleCheck" :destroy-on-close="true" :lock-scroll="false">
      <el-card  shadow="never">
        <el-form :model="editForm"   label-width="170px">
          <el-form-item label="今日取消订单量:" prop="money">
            {{checkForm.today_cancel}}
          </el-form-item>
          <el-form-item label="本月累计取消订单量:" prop="invite_money">
            {{checkForm.thisMon_cancel}}
          </el-form-item>
          <el-form-item label="本月累计接单量：" prop="total_time">
            {{checkForm.month_order_total}}
          </el-form-item>
          <el-form-item label="本月总收益：" prop="money">
            {{checkForm.month_order_price}}
          </el-form-item>
          <el-form-item label="本月订单收益：" prop="invite_money">
            {{checkForm.month_order_earning}}
          </el-form-item>
          <el-form-item label="本月红包收益：" prop="total_time">
            {{checkForm.month_order_fee}}
          </el-form-item>
          <el-form-item label="本月推广收益：" prop="total_time">
            {{checkForm.month_order_promote}}
          </el-form-item>
          <el-form-item label="司机姓名：" prop="true_name" >
            {{checkForm.name}}
          </el-form-item>
          <el-form-item label="头像：" >
            <img :src="checkForm.true_head" width="70"   height="70" />
          </el-form-item>
          <el-form-item label="是否载客" prop="phone">
            {{checkForm.is_carry}}
          </el-form-item>
          <el-form-item label="手机号：" prop="phone">
            {{checkForm.phone}}
          </el-form-item>
          <el-form-item label="所属区域：">
            {{checkForm.driver_area}}
          </el-form-item>
          <el-form-item label="累计接单收益：" prop="money">
            {{checkForm.money}}
          </el-form-item>
          <el-form-item label="诚信度：" prop="invite_money">
            {{checkForm.credit}}
          </el-form-item>
          <el-form-item label="累计在线时长：" prop="total_time">
            {{checkForm.total_time*1000 | toDateString }}
          </el-form-item>
          <el-form-item label="最近的登录时间：" prop="money">
            {{checkForm.active_time*1000 | toDateString }}
          </el-form-item>
          <el-form-item label="注册时间：" prop="invite_money">
            {{checkForm.create_time*1000 | toDateString }}
          </el-form-item>
          <el-form-item label="性别：" prop="total_time">
            {{checkForm.sex}}
          </el-form-item>
          <el-form-item label="头像：" >
            <img :src="checkForm.true_head" width="70"   height="70" />
          </el-form-item>
          <el-form-item label="真实姓名：" prop="total_time">
            {{checkForm.true_name}}
          </el-form-item>
          <el-form-item label="身份证号：" prop="idcard">
            {{checkForm.idcard}}
          </el-form-item>
          <el-form-item label="支付宝账号：" prop="idcard">
            {{checkForm.ali_number}}
          </el-form-item>
          <el-form-item label="车牌号：" prop="car_number">
            {{checkForm.car_number}}
          </el-form-item>
          <el-form-item label="手持身份证照片：" >
            <img :src="checkForm.idcard_hand" width="70"   height="70" />
          </el-form-item>
          <el-form-item label="身份证正面照：" >
            <img :src="checkForm.idcard_front" width="70"   height="70" />
          </el-form-item>
          <el-form-item label="身份证反面照：" >
            <img :src="checkForm.idcard_back" width="70"   height="70" />
          </el-form-item>
          <el-form-item label="营业执照：" >
            <img :src="checkForm.business_license" width="70"   height="70" />
          </el-form-item>
          <el-form-item label="食品安全许可证：" >
            <img :src="checkForm.food_safety" width="70"   height="70" />
          </el-form-item>
          <el-form-item label="公司法人身份证正面：" >
            <img :src="checkForm.company_legal_idcard_font" width="70"   height="70" />
          </el-form-item>
          <el-form-item label="公司法人身份证反面：" >
            <img :src="checkForm.company_legal_idcard_back" width="70"   height="70" />
          </el-form-item>
        </el-form>
      </el-card>
    </el-dialog>
    <!-- 编辑司机 -->
    <el-dialog v-dialogDrag title="编辑司机" :visible.sync="dialogTableVisibleEdit" :destroy-on-close="true" :lock-scroll="false">
      <el-card  shadow="never">
        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="170px">
          <el-form-item label="真实姓名：" prop="true_name" >
            <el-input v-model="editForm.name" placeholder="请输入" clearable/>
          </el-form-item>
          <el-form-item label="联系方式：" prop="phone">
            <el-input v-model="editForm.phone" placeholder="请输入" clearable/>
          </el-form-item>
          <el-form-item label="所属区域：">
            <el-select v-model="editForm.pname"  @change="handleChangeProv1(editForm.pname)" class="selectStyle" placeholder="请选择省">
              <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
            </el-select>
            <el-select v-model="editForm.cname"  @change="handleChangeCity1(editForm.cname)" class="selectStyle ml-10" placeholder="请选择市">
              <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
            </el-select>
            <el-select v-model="editForm.aname"   @change="$forceUpdate(),handleDis(editForm.aname)" class="selectStyle ml-10" placeholder="请选择区/县">
              <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="类型:" >
            <el-select v-model="editForm.type" placeholder="请选择类型">
              <el-option label="司机" value="1"></el-option>
              <el-option label="商铺" value="2"></el-option>
              <el-option label="超市" value="3"></el-option>
              <el-option label="跑腿" value="4"></el-option>
              <el-option label="叫餐" value="5"></el-option>
              <el-option label="生活" value="6"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="累计接单收益：" prop="money">
            {{editForm.money}}
          </el-form-item>
          <el-form-item label="推广总收益：" prop="invite_money">
            {{editForm.invite_money}}
          </el-form-item>
          <el-form-item label="累计在线时长：" prop="total_time">
            {{editForm.total_time*1000 | toDateString }}
          </el-form-item>
          <el-form-item label="诚信度：" prop="invite_money">
            {{editForm.credit}}
          </el-form-item>
          <el-form-item label="性别：" prop="sex">
            <el-radio-group v-model="editForm.sex">
              <el-radio label="男">男</el-radio>
              <el-radio label="女">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="身份证号：" prop="idcard">
            {{editForm.idcard}}
          </el-form-item>
          <el-form-item label="车牌号：" prop="car_number">
            {{editForm.car_number}}
          </el-form-item>
          <el-form-item label="车牌数量：" prop="car_persons">
            {{editForm.car_persons}}
          </el-form-item>

          <el-form-item label="真实头像：" >
            <uploadImage :limit="1" v-model="editForm.true_head"></uploadImage>
          </el-form-item>
          <el-form-item label="手持身份证照片：" >
            <uploadImage :limit="1" v-model="editForm.idcard_hand"></uploadImage>
          </el-form-item>
          <el-form-item label="身份证正面照：" >
            <uploadImage :limit="1" v-model="editForm.idcard_front"></uploadImage>
          </el-form-item>
          <el-form-item label="身份证反面照：" >
            <uploadImage :limit="1" v-model="editForm.idcard_back"></uploadImage>
          </el-form-item>

          <el-form-item label="驾驶证正面照：" >
            <uploadImage :limit="1" v-model="editForm.jiacard_front"></uploadImage>
          </el-form-item>
          <el-form-item label="行驶证正面照：" >
            <uploadImage :limit="1" v-model="editForm.xingcard_front"></uploadImage>
          </el-form-item>
          <el-form-item label="车辆正面照：" >
            <uploadImage :limit="1" v-model="editForm.car_front"></uploadImage>
          </el-form-item>
          <el-form-item label="车辆侧面照：" >
            <uploadImage :limit="1" v-model="editForm.car_profile"></uploadImage>
          </el-form-item>
          <el-form-item label="出租车运营资格证：" >
            <uploadImage :limit="1" v-model="editForm.taxi_operation"></uploadImage>
          </el-form-item>



          <el-form-item label="累计发单量：" prop="orders">
            {{editForm.orders}}
          </el-form-item>
          <el-form-item label="注册时间：" prop="create_time">
            {{ editForm.create_time*1000 | toDateString }}
          </el-form-item>
          <el-form-item label="最近活跃时间：" prop="active_time">
            {{ editForm.active_time*1000 | toDateString }}
          </el-form-item>
          <el-form-item label="用户余额：" prop="money">
            {{editForm.money}}
          </el-form-item>
          <el-form-item label="是否启用：" prop="status">
            <el-radio-group v-model="editForm.status">
              <el-radio :label="1">未完善信息</el-radio>
              <el-radio :label="2">正常</el-radio>
            </el-radio-group>
          </el-form-item>

        </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="dialogTableVisibleEdit=false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
    <!-- 查看出车记录 -->
    <el-dialog v-dialogDrag title="查看出车记录" :visible.sync="dialogTableVisibleCarRecords" class="records" :destroy-on-close="true" :lock-scroll="false">
      <el-card  shadow="never">
        <div style="margin-left:28px">
          <div><span class="foWei6">商家姓名：</span>{{driverData.name}} </div>
          <div><span class="foWei6">类型：</span>{{driverData.type}} </div>
          <div><span class="foWei6">手机号：</span>{{driverData.phone}} </div>
          <div><span class="foWei6">地区：</span>{{driverData.area}} </div>
        </div>
        <div class="greenLine"></div>
        <div class="statist">出车统计</div>
        <div id="main" style="width:100%;height:300px;"></div>
      </el-card>
    </el-dialog>

    <!-- 检测记录 -->
    <el-dialog v-dialogDrag title="检测记录" :visible.sync="dialogTableVisibleRecords">
      <el-card  shadow="never">
        <el-form :model="table1.where" label-width="77px" class="ele-form-search mb-10 d-flex" style="float:right">
          <el-form-item label="开始时间:">
            <el-date-picker  style="width:150px"
                             v-model="table.where.create_time"
                             type="date"
                             placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间:">
            <el-date-picker  style="width:150px"
                             v-model="table.where.create_time"
                             type="date"
                             placeholder="选择日期">
            </el-date-picker>
            <el-button type="primary" @click.native="searchRecords()" icon="el-icon-search" class="ele-btn-icon  ml-20">搜索</el-button>
          </el-form-item>
        </el-form>
        <el-table  :data="recordsList"  element-loading-text="列表正在加载中" border fit highlight-current-row style="width: 100%;">
          <el-table-column type="selection" width="45" align="center" fixed="left"/>
          <el-table-column type="index"  label="编号" width="60" align="center" fixed="left" show-overflow-tooltip/>
          <el-table-column prop="name" label="司机姓名"  show-overflow-tooltip min-width="120"/>
          <el-table-column prop="car_number" label="司机车牌号"  show-overflow-tooltip min-width="120"/>
          <el-table-column prop="name" label="司机电话"  show-overflow-tooltip min-width="120"/>
          <el-table-column prop="true_head" label="司机认证人像"  show-overflow-tooltip min-width="120"/>
          <el-table-column prop="create_time" label="出/收车检测时间"  show-overflow-tooltip min-width="160"/>
          <el-table-column prop="face_img" label="检测上传图片"  show-overflow-tooltip min-width="120"/>
        </el-table>
        <el-pagination v-if="recordsShow"
                       @current-change="handleCurrentChange"
                       :current-page.sync="pageNumber"
                       layout="total, prev, pager, next, jumper"
                       :page-sizes="[5, 10, 15, 20]"
                       :page-size="100"
                       :total="recordsTotal">
        </el-pagination>
      </el-card>
    </el-dialog>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import uploadImage from '@/components/uploadImage'

export default {
  name: "SysUser",
  components: {uploadImage},
  data() {
    return {
      table: {url: '/driver/index', where: {type:4}},  // 表格配置
      table1: {url: '/driver/driver_check_log', where: {}},  // 表格配置
      changePro:false,
      choose: [],  // 表格选中数据
      showEdit: false,  // 是否显示表单弹窗
      editForm: {},  // 表单数据
      editRules: {  // 表单验证规则
        // phone: [
        //   {required: true, message: '请输入手机号', trigger: 'blur'}
        // ],
        // name: [
        //   {required: true, message: '请输入昵称', trigger: 'blur'}
        // ],
      },
      provArr:[],
      cityArr:[],
      districtArr:[],
      checkForm:{},
      recordsList:[],
      startTime: '',
      endTime: '',
      driverData:{},
      dialogTableVisibleEdit:false,
      dialogTableVisibleCheck:false,
      dialogTableVisibleRecords:false,
      dialogTableVisibleCarRecords:false,
      pageSize:10,
      pageNumber:1,
      recordsTotal:1000,
      recordsShow:false,
      recordsId:'',
      loading:true,
      pid:'',
      cid:'',
      aid:''
    }
  },
  created(){


    this.$http.get('/common/province_list').then(res=>{
      let data=JSON.parse(res.data)
      this.provArr=data
    })

  },
  computed: {
    ...mapGetters(["permission"]),
  },
  mounted() {
  },
  methods: {
    dropClick(command,row){
      if(command==='edit'){// 查看用户信息
        this.edit()
      }else if(command==='remove'){//删除
        this.remove()
      }else if(command==='out_list'){//出车记录
        this.handleCarRecords(row)
      }else if(command==='check_list'){//检测记录
        this.handleRecords(row)
      }else if(command==='edit'){//修改用户信息
        this.edit(row)
      }else if(command==='forlimit'){//限号
        this.forlimit(row)

      }if(command==='blackData'){//拉入黑名单
        this.blackData(row)

      }
    },

    /**
     *选择省
     **/
    handleChangeProv(e){
      /** 获取被选省省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table.where.cid=''
        this.table.where.aid=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.table.where.aid=''
      })
			}else{
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
    },
    handleChangeProv1(e){
      /** 获取被选省省份的pid**/
      this.changePro=true
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.editForm.cname=''
        this.editForm.aname=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity1(e){
			if(e){
      /** 获取被选市的cid**/
      this.changePro=true
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.editForm.aname=''

      })
			}else{
				this.editForm.pname = ''
				this.editForm.aname = ''
			}
    },
    handleDis(){
      this.changePro=true
    },

    /**
     *修改用户信息
     **/
    handleEdit(row){
      this.dialogTableVisibleEdit=true
      this.editForm = row;
    },
    /**
     *查看用户信息
     **/
    handlecheck(row){
      this.dialogTableVisibleCheck=true
      if(row.is_carry==1){
        row.is_carry='载客'
      }else{
        row.is_carry='空闲'
      }
      if(row.status==2){
        row.status='正常'
      }else(
          row.status='待完善信息'
      )
      this.checkForm = row;
    },
    /**
     *检测记录  pageSize（每页的条数），pageNumber（页码）
     **/
    handleRecords(row){
      this.dialogTableVisibleRecords=true
      this.recordsId=row.id
      this.getRecords(row.id)

    },
    getRecords(id){
      var formData={
        id:id,
        pageSize:this.pageSize,
        pageNumber:this.pageNumber,
        strat_time:this.startTime,
        end_time:this.endTime

      }
      this.$http.post('/driver/driver_check_log',formData).then(res=>{
        var data=JSON.parse(res.data)
        this.recordsTotal=data.total
        if(data.total==0 || data.total=='null'){
          this.recordsShow=false
        }else{
          this.recordsShow=true
        }
        if(data.rows.length){
          this.recordsList=data.rows

        }


      })
    },
    /**
     *查询
     **/
    searchRecords(){


      this.getRecords(this.recordsId)
    },
    /**
     *分页
     **/
    handleCurrentChange(e){
      //console.log(e)
      this.pageNumber=e
      this.getRecords(this.recordsId)
    },
    /**
     *查看出车记录
     **/
    handleCarRecords(row){
      this.dialogTableVisibleCarRecords=true
      const loading = this.$loading({lock: true, target: document.querySelector(".records")});
      this.$http.post('/driver/driver_log',{id:row.id}).then(res => {
        loading.close()
        let data=JSON.parse(res.data)
        this.driverData=data.driver_data
        let xData=data.x_datas
        let yData=data.y_datas
        this.$nextTick(()=>{
          let dom = document.getElementById('main')
          let myChart = this.$echarts.init(dom);
          let option;
          option = {

            toolbox: {
              show: true,
              feature: {
                saveAsImage: {//保存图片
                  show: true
                },
              }
            },
            xAxis: {
              type: 'category',
              data: xData
            },
            yAxis: {
              type: 'value'
            },
            series: [{
              data:yData,
              type: 'line',
              itemStyle : {
                normal : {
                  color:'#009F95',
                  lineStyle:{
                    color:'#009F95'
                  }
                }
              },
            }],

          };
          option && myChart.setOption(option);
        })
      })
    },

    /**
     * 显示编辑
     */
    edit(row) {
      this.$http.get('/driver/info?id=' + row.id).then(res => {
        if (res.data.code === 0) {
          this.editForm = res.data.data;
          this.showEdit = true;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
    /**
     * 保存编辑
     */
    save() {

      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({lock: true});

          if(this.changePro==true){
            this.editForm.pid=this.editForm.pname
            this.editForm.cid=this.editForm.cname
            this.editForm.aid=this.editForm.aname
            this.changePro=false
          }

          this.$http.post('/driver/edit', this.editForm).then(res => {
            loading.close();
            this.dialogTableVisibleEdit=false
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /**
     * 刪除(批量刪除)
     */
    remove(row) {
      //console.log(row)
      if (!row) {  // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
        let ids = this.choose.map(d => d.id);
        this.$confirm('确定要删除选中的司机吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/driver/delete', {id: ids}).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        }).catch(() => 0);
      } else {  // 单个删除
        const loading = this.$loading({lock: true});
        this.$http.post('/driver/delete', {id:row.id}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: res.data.msg});
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      }
    },
    blackData(row){
      const loading = this.$loading({lock: true});
      this.$http.post('/driver/forblack', {id:row.id}).then(res => {
        loading.close();
        if (res.data.code === 0) {
          this.$message({type: 'success', message: res.data.msg});
          this.$refs.table.reload();
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        loading.close();
        this.$message.error(e.message);
      });
    },
    forlimit(row){

      this.$confirm('确定把该司机限号吗?', '提示', {type: 'warning'}).then(() => {
        const loading = this.$loading({lock: true});
        this.$http.post('/driver/forlimit', {id:row.id}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: res.data.msg});
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      })

    },
    /* 导出数据Excel */
    exportExcel() {
      let info = JSON.parse(JSON.stringify(this.table.where));
      // let name = this.table.where.name;
      // let phone = this.table.where.phone;
      // let cid = this.table.where.cid;
      // let pid = this.table.where.pid;
      // let area_id = this.table.where.area_id;
      // let page = this.table.where.area_id;
      //console.log('user data export')
      this.$http
          // .get("/user/exportExcel", {'name':name,'phone':phone,'pid':pid,'cid':cid,'area_id':area_id})
          .get("/driver/exportExcel", info)
          .then((res) => {
            let data = res.data;
            if (data.code == 0) {
              // 下载文件
              window.location.href = data.data;
              this.$message({
                type: "success",
                message: "导出成功",
              });
            } else {
              this.$message.error("导出失败");
            }
          })
    },
  }
}
</script>

<style scoped>
.ele-block .el-upload-dragger {
  width: 100%;
}



</style>